import React from 'react'

import './jobitem.scss'

import { Tag, Timeline, Collapse } from 'antd'
const { Item } = Timeline
const { Panel } = Collapse;

const uuid = require('uuid/v1');

function JobItem({job}) {
  const {
    img_key,
    company,
    primary_position,
    previous_positions,
    description_points,
    show,
    stack,
    final_item = false
  } = job

  return (
    <div className={final_item ? 'finalItem': null}>
      <Item dot={<img alt='logo' src={`/images/${img_key}.png`} className='dot'/>}>
        <h2 className='companyTitle'>{company}</h2>
        <div className='primaryPosition'>
          <h3>{primary_position.title}</h3>
          <h4 className='timeframe'>{primary_position.timeframe}</h4>
        </div>
        {/* <Collapse defaultActiveKey={['1']}>
        <Panel key={show} header=''> */}
        {previous_positions ?
        previous_positions.map(position =>
          <div className='previousPosition' key={uuid()}>
            <p>{position.title}</p><p className='timeframe'>{position.timeframe}</p>
          </div>
        ) : null} {/* > */}
        {stack ?
        stack.map( tech =>
          <Tag className='' key={tech}>{tech}</Tag>
        ): null}
        {description_points ? description_points.map(sections =>
          <ul className='descSection' key={sections.main}>
            <li className='mainPoint'>{sections.main}</li>
            <ul>
              {sections.subs && sections.subs.map(subpoint =>
                <li className='subPoint' key={subpoint}>{subpoint}</li>
              )}
            </ul>
          </ul>
        ) : null}
      {/* </Panel>
      </Collapse> */}
      </Item>
    </div>
  )
}

export default JobItem
