import React from 'react'
import './header.scss'

import { Icon } from 'antd'
import { classJoin } from 'css-classname';

const headerClass = (isMobileOnly) => classJoin(
  'header',
  isMobileOnly ? 'mobileHeader' : 'dockedHeader'
)

const headlineClass = (isMobileOnly) => classJoin(
  'headline',
  isMobileOnly ? 'mobileHeadline' : 'dockedHeadline'
)

const Header = ({isMobileOnly}) => (
  <header className={headerClass(isMobileOnly)}>
    <h3 className={headlineClass(isMobileOnly)}>JAKE FISHBAIN</h3>
    <h5 className='role'>SOLUTIONS ARCHITECTURE</h5>
    <div className='contact'>
      <Icon type="mail" className='emailIcon'/>
        <a href='mailto:jakefishbain@gmail.com' className='email'>{!isMobileOnly && 'jakefishbain@gmail.com'}</a>
      <Icon type="phone" className='phoneIcon'/>
        <a href='tel:1-847-804-2730' className='phone'>{!isMobileOnly && '847.804.2730'}</a>
      <Icon type="laptop" className='websiteIcon'/>
        <a target='_blank' rel="noopener noreferrer" href='http://resume.jakefishbain.com' className='website'>
          {!isMobileOnly && 'jakefishbain.com'}
        </a>
      <Icon type="linkedin" className='linkedinIcon'/>
        <a target='_blank' rel="noopener noreferrer" href='https://www.linkedin.com/in/jakefishbain/'
        className='github'>
          {!isMobileOnly && 'linkedin.com/in/jakefishbain'}
        </a>
      <Icon type="github" className='githubIcon'/>
        <a target='_blank' rel="noopener noreferrer" href='https://github.com/jakefishbain'
        className='github'>
          {!isMobileOnly && 'jakefishbain'}
        </a>
    </div>
  </header>
)

export default Header
