import React from 'react';
import './App.scss';
import Experience from './components/experience'
import Education from './components/education'
import Header from './components/header'

import jobs from './job_experience.json'
import schools from './education.json'
import { summary, skills } from './constants'

import { Divider, BackTop, Tag, Icon, Tooltip } from 'antd'
import { classJoin } from 'css-classname';

import ReactOrientation from 'react-orientation'
import { isMobileOnly } from "react-device-detect"

// const PDF_URL = 'https://bit.ly/3juRQhF'
const PDF_URL = '/jakefishbain_resume_060423.pdf'

function App() {

  const skillsDividerClass = (isMobileOnly) => classJoin(
    'divider',
    isMobileOnly && 'skillsDividerMobile'
  )

  return (
    <div className='App'>
      <ReactOrientation type='landscape'>
        <div className='orientationMessage'>
          <div>ROTATE YOUR PHONE</div>
          <Icon className='rotateIcon' type="shake" />
        </div>
      </ReactOrientation>
      <BackTop />
        <a className='pdfLink' href={PDF_URL} target='_blank'>
          <Tooltip placement="rightTop" title='PDF VERSION'>
            <Icon className='pdfArrow' type="file-pdf" />
          </Tooltip>
        </a>
        <Header
          isMobile={isMobileOnly}
        />
      <Divider className='topDivider'/>
        <div className='sectionContainer'>
          <p className='summary'>{summary}</p>
        </div>
      <Divider className='divider'/>
        <h1 className='sectionHeader'>EXPERIENCE</h1>
        <div className='sectionContainer'>
          <Experience jobs={jobs}/>
        </div>
      <Divider className='divider'/>
      <h1 className='sectionHeader'>TECHNICAL SKILLS</h1>
        <div className='sectionContainer'>
          <div className={'skillsList'}>
            {skills.map(skill => <Tag className='skill' key={skill}>{skill}</Tag>)}
          </div>
        </div>
      <Divider className={skillsDividerClass(isMobileOnly)}/>
      
      <h1 className='sectionHeader'>EDUCATION</h1>
        <div className='sectionContainer'>
          <Education schools={schools} />
        </div>
    </div>
  );
}

export default App;
