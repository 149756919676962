import React from 'react';
import './experience.scss' 

import JobItem from '../jobitem'

import { Timeline, Collapse } from 'antd'
const { Panel } = Collapse;

const renderJobItem = (job) => <JobItem job={job} key={job.company}/>

const Experience = ({jobs}) => (
  <div className='timelineContainer'>
    <Timeline>
      {jobs.map(job => renderJobItem(job))}
    </Timeline>
  </div>
)

export default Experience
