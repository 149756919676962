import React from 'react'
import './schoolitem.scss'

import { Timeline } from 'antd'
const { Item } = Timeline

export default function SchoolItem({school}) {
  const {
    title,
    degree,
    timeframe,
    img_key,
    final_item = false
  } = school

  return(
    <div className={final_item ? 'finalItem' : null}>
      <Item dot={<img alt='logo' src={`/images/${img_key}.png`} className='dot'/>}>
        <div className='edHeader'>
          <h2 className='schoolTitle'>{title}</h2>
          <h4 className='timeframe'>{timeframe}</h4>
        </div>
        <ul>
          <li className='degree'>{degree}</li>
        </ul>
      </Item>
    </div>
  )
}