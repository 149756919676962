import React from 'react';
import './education.scss' 

import SchoolItem from '../schoolitem'

import { Timeline } from 'antd'

const renderSchoolItem = (school) => <SchoolItem school={school} key={school.title}/>

const Education = ({schools}) => (
  <div className='timelineContainer'>
    <Timeline>
      {schools.map(school => renderSchoolItem(school))}
    </Timeline>
  </div>
)

export default Education