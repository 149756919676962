// export const summary = 'Dedicated developer, teacher, and candy-lover, I am a motivated self proclaimed \'educoder\' striving to help others through knowledge and compassion. Having taught special education in a variety of settings, I believe communication holds together the framework of any successful partnership. The same is true in programming, regardless of the language. I am a capable developer with a creative touch. Merging my love for learning, teaching and technology, the possibilities are endless!'

export const summary = `Dedicated solutioner, teacher, and candy-lover, I am a motivated 'edu-coder' striving to help others through knowledge and compassion. I approach a challenge with an open mind, breaking down the issue into its individual parts and tackling each one systematically.

I pride myself in being able to connect the dots, implement a plan, and share my learnings.

I strive to create solutions that directly meet and align with business objectives and ensure shared milestones and goals are achieved and exceeded.

Having taught and led teams across various settings, I believe communication holds together the framework of any successful partnership. The same is true in architecting a solution to implement, regardless of the industry, vertical, or otherwise.

I am a capable problem solver and leader with a creative touch. Merging my love for learning, teaching and technology, the possibilities are endless!`

export const skills =[
  'Agile Methodologies/Scrum',
  'Pair Programming Principles',
  'JavaScript',
  'Angular',
  'Node',
  'Express',
  'TypeScript',
  'React',
  'Ruby/Ruby On Rails',
  'Document Based Databases (MongoDB, NoSQL)',
  'SQL/Relational Databases (PostgreSQL, MySQL)',
  'GraphQL',
  'HTML/CSS',
  'git',
  'RPA',
  'jQuery',
  'Object Oriented Programming (OOP)',
  'TDD Principals/Testing Languages',
//   'lodash',
//   'ramda',
  'Functional Programming'
]
